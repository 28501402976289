<template>
  <div class="from-our-directory__wrapper">
    <a-lazy-hydrate when-visible>
      <a-section-title
        title="From our Directory"
        :link="linkToFmDirHomepage"
        :data-ref="$options.consts.REFS.DIRECTORY_SECTION_COMPANIES"
        open-in-new-tab
        class="from-our-directory__title"
      />
    </a-lazy-hydrate>

    <template v-if="isDataValid.DirectoryCategories">
      <a-visibility
        v-if="!isCategoriesVisibleOnMobile"
        show
        :on="[$breakpoint.mobile]"
      >
        <a-button
          class="from-our-directory__show-categories-button"
          @click="showCategoriesOnMobile"
        >
          View all categories
        </a-button>
      </a-visibility>
      <div
        class="from-our-directory__categories"
        :class="dynamicCategoriesClass"
      >
        <a-global-quicksearch
          :source="$options.consts.QUICKSEARCH_SOURCE.FM_DIR"
          :single-column="quicksearchSingleColumn"
          placeholder="Find a service"
          class="from-our-directory__search"
        />

        <category-list
          :categories="categories"
          :last-modified="lastModifiedByRequest.DirectoryCategories"
        />
      </div>
    </template>

    <company-list
      v-if="isDataValid.DirectoryCompanies"
      :companies="companies"
      :last-modified="lastModifiedByRequest.DirectoryCompanies"
      class="from-our-directory__company-list"
    />

    <a-visibility
      v-if="isDataValid.DirectoryNews"
      hide
      :on="[$breakpoint.mobile]"
    >
      <news-list
        :articles="articles"
        :last-modified="lastModifiedByRequest.DirectoryNews"
      />
    </a-visibility>

    <a-visibility hide :on="[$breakpoint.mobile]">
      <a-assistant-button
        class="from-our-directory__assistant"
        :button-id="$options.consts.ASSISTANT_BUTTON_ID.DIRECTORY_SECTION"
      >
        <div class="from-our-directory__assistant-title">
          <span>Looking for service?</span>
          <span class="from-our-directory__we-can-help">We can Help</span>
        </div>
      </a-assistant-button>
    </a-visibility>
  </div>
</template>
<script>
import { PROP_TYPES, propValidator } from '@/utils/validators'
import { hydrationHelpers } from '@/utils/mixins/hydrationHelpers'

import ASectionTitle from '@/components/_homepage/Shared/ASectionTitle'
import { REFS } from 'enums/external-refs'
import AGlobalQuicksearch from 'shared/AGlobalQuicksearch'
import { QUICKSEARCH_SOURCE } from 'shared/AGlobalQuicksearch/enums'
import scss from '@/utils/scss'
import { ASSISTANT_BUTTON_ID } from 'shared/AAssistantButton/enums'
import { hydrateWhenVisible } from '@/utils/helpers/vue-lazy-hydration/LazyHydrate'

const { breakpointNames } = scss

const QUICKSEARCH_SINGLE_COLUMN_BY_BREAKPOINT = {
  [breakpointNames.mobile]: true,
  [breakpointNames.tablet]: true,
  [breakpointNames.desktopSm]: true,
  [breakpointNames.desktopMd]: false,
  [breakpointNames.desktopLg]: false,
  [breakpointNames.desktopXl]: true,
  [breakpointNames.aboveDesktopXl]: true
}

export default {
  name: 'HomepageDirectory',
  mixins: [hydrationHelpers],
  components: {
    AAssistantButton: hydrateWhenVisible(
      () => import('shared/AAssistantButton'),
      { props: ['button-id'] }
    ),
    AGlobalQuicksearch,
    ASectionTitle,
    CategoryList: hydrateWhenVisible(() => import('./CategoryList'), {
      props: ['categories', 'last-modified']
    }),
    CompanyList: hydrateWhenVisible(() => import('./CompanyList'), {
      props: ['companies', 'last-modified']
    }),
    NewsList: hydrateWhenVisible(() => import('./NewsList'), {
      props: ['articles', 'last-modified']
    })
  },
  props: {
    categories: propValidator([PROP_TYPES.ARRAY]),
    companies: propValidator([PROP_TYPES.ARRAY]),
    articles: propValidator([PROP_TYPES.ARRAY]),
    isDataValid: propValidator([PROP_TYPES.OBJECT]),
    lastModifiedByRequest: propValidator([PROP_TYPES.OBJECT])
  },
  consts: { REFS, QUICKSEARCH_SOURCE, ASSISTANT_BUTTON_ID },
  data() {
    return {
      linkToFmDirHomepage: this.$env.FM_DIR_DOMAIN_URL,
      isCategoriesVisibleOnMobile: false
    }
  },
  computed: {
    quicksearchSingleColumn() {
      return QUICKSEARCH_SINGLE_COLUMN_BY_BREAKPOINT[
        this.$_hydrationHelpers_getCurrentBreakpoint().name
      ]
    },
    dynamicCategoriesClass() {
      return {
        'show-on-mobile': this.isCategoriesVisibleOnMobile
      }
    }
  },
  methods: {
    showCategoriesOnMobile() {
      this.isCategoriesVisibleOnMobile = true
    }
  }
}
</script>

<style scoped lang="scss">
@import '~assets/scss/local/mixins/grid';

.from-our-directory__wrapper {
  $padding: (
    $above-desktop-xl: 15px,
    $tablet: 15px 15px 20px 15px,
    $mobile: 10px 20px 20px
  );

  @include setStyleByBreakpoint('padding', $padding);

  border-radius: 5px;

  .from-our-directory__title {
    @include mobile {
      align-items: center;
    }
  }

  .from-our-directory__categories {
    @include mobile {
      display: none;
    }

    &.show-on-mobile {
      @include mobile {
        display: block;
      }
    }
  }

  .from-our-directory__show-categories-button {
    @include mobile {
      width: 100%;
      text-transform: uppercase;
      font-weight: 400;
    }
  }

  /deep/ .from-our-directory__title .homepage-section-title__title {
    $font-size: (
      $desktop-md: 21px,
      $tablet: 25px
    );

    @include setStyleByBreakpoint('font-size', $font-size);
  }

  .from-our-directory__search {
    margin-bottom: 25px;

    /deep/ .input__component-wrapper {
      border: 1px solid $c--black;
      border-radius: 5px;
    }
  }

  /deep/ .from-our-directory__company-list {
    margin-top: 25px;
  }

  /deep/ .from-our-directory__assistant {
    width: 100%;
    height: 53px;

    @include desktop-md {
      height: 68px;
    }

    @include tablet {
      width: 357px;
      height: 42px;
      margin: 20px auto 0;
    }
  }

  .from-our-directory__assistant-title {
    display: flex;
    justify-content: center;

    @include desktop-md {
      flex-direction: column;
    }

    @include tablet {
      flex-direction: row;
    }
  }

  .from-our-directory__assistant-title span {
    $font-size: (
      $above-desktop-xl: 18px,
      $desktop-lg: 15px,
      $tablet: 16px
    );

    @include setStyleByBreakpoint('font-size', $font-size);

    line-height: 20px;
    font-weight: 400;
  }

  .from-our-directory__we-can-help {
    $margin-left: (
      $above-desktop-xl: 10px,
      $desktop-md: 0,
      $tablet: 10px
    );

    @include setStyleByBreakpoint('margin-left', $margin-left);
  }
}
</style>
