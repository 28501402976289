import scss from '@/utils/scss'
import { b } from '@/components/_homepage/Shared/ACombinedComponent/helpers'

const { breakpoints } = scss

function am(articleIndex, topicLineCount, topicLineHeight, fixedHeight) {
  if (articleIndex == null) return {}

  return {
    slotName: 'articleSlot',
    getData: ({ data }) => ({
      article: data?.Articles?.[articleIndex],
      topicLineCount,
      topicLineHeight,
      fixedHeight
    })
  }
}

/**
 * c()                              - Category Name
 * a(articleIndex, topicLineCount)  - Article slot without media.
 * am(articleIndex, topicLineCount) - Article slot with media.
 * b()                              - Banner
 */
export const gridContentSettings = {
  [breakpoints.aboveDesktopXl]: [
    [am(0, 3, 26, true)],
    [am(0, 3, 26, true), b()],
    [am(0, 3, 26, true)],
    [am(0, 3, 26, true)],
    [am(0, 3, 26, true)]
  ],
  [breakpoints.desktopXl]: [
    [am(0, 3, 26, true)],
    [am(0, 3, 26, true), b()],
    [am(0, 3, 26, true)],
    [am(0, 3, 26, true)],
    [am(0, 3, 26, true)]
  ],
  [breakpoints.desktopLg]: [
    [am(0, 3, 26, true)],
    [am(0, 3, 26, true), b()],
    [am(0, 3, 26, true)],
    [am(0, 4, 26, true)],
    [am(0, 4, 26, true)]
  ],
  [breakpoints.desktopMd]: [
    [am(0, 4, 22, true)],
    [am(0, 4, 22, true)],
    [am(0, 4, 22, true)],
    [am(0, 4, 22, true), b()],
    [am(0, 4, 22, true)]
  ],
  [breakpoints.desktopSm]: [
    [am(0, 4, 22, true)],
    [am(0, 4, 22, true)],
    [am(0, 4, 22, true)],
    [am(0, 4, 22, true), b()],
    [am(0, 4, 22, true)]
  ],
  [breakpoints.tablet]: [
    [am(0, 2, 26, true)],
    [am(0, 2, 26, true), b()],
    [am(0, 2, 26, true)],
    [am(0, 3, 26, true)],
    [am(0, 3, 26, true)]
  ],
  [breakpoints.mobile]: [
    [am(0, 2, 22, false)],
    [am(0, 2, 22, false)],
    [am(0, 2, 22, false), b()],
    [am(0, 3, 22, false)]
  ]
}
