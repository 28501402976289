<template>
  <div class="homepage-education-center__wrapper">
    <a-section-title
      title="Education Center"
      :link="$options.consts.linkToEducationCenter"
      delimiter
      class="homepage-education-center__title"
    />

    <a-article-list
      :articles="articles"
      id-field="Id"
      class="homepage-education-center__article-list"
    >
      <template v-slot="{ article, position }">
        <template v-for="breakpoint in $breakpoint">
          <a-visibility show :on="[breakpoint]" :key="breakpoint">
            <a-combined-component
              :grid-content-settings="$options.consts.gridContentSettings"
              :breakpoint="breakpoint"
              :data="article"
              :position="position"
            >
              <template #articleSlot="{ data }">
                <article-slot
                  :article="article"
                  :topic-line-count="data.topicLineCount"
                  :topic-line-height="data.topicLineHeight"
                  :fixed-height="data.fixedHeight"
                />
              </template>
              <template #banner>
                <banner-slot
                  class="homepage-education-center__banner-wrapper"
                />
              </template>
            </a-combined-component>
          </a-visibility>
        </template>
      </template>
    </a-article-list>
  </div>
</template>

<script>
import { PROP_TYPES, propValidator } from '@/utils/validators'
import { serverCacheKey } from '@/utils/mixins/serverCacheKey'
import ASectionTitle from '@/components/_homepage/Shared/ASectionTitle'
import AArticleList from 'shared/AArticleList'
import ArticleSlot from 'shared/ArticleSlots/Fm/Shared/AArticleSlotEducationCenter'
import BannerSlot from './BannerSlot'
import { gridContentSettings } from './enums'
import { ROUTE_NAME } from 'enums/routes'
import { SPECIAL_CATEGORY } from 'enums/special-categories'
import { hydrateWhenVisible } from '@/utils/helpers/vue-lazy-hydration/LazyHydrate'

export default {
  name: 'HomepageEducationCenter',
  mixins: [serverCacheKey],
  components: {
    AArticleList,
    ASectionTitle,
    ACombinedComponent: hydrateWhenVisible(
      () => import('@/components/_homepage/Shared/ACombinedComponent'),
      { props: ['grid-content-settings', 'breakpoint', 'data', 'position'] }
    ),
    ArticleSlot,
    BannerSlot
  },
  props: {
    educationCenterArticles: propValidator([PROP_TYPES.ARRAY]),
    thoughtLeadershipArticles: propValidator([PROP_TYPES.ARRAY])
  },
  consts: {
    gridContentSettings,
    linkToEducationCenter: {
      name: ROUTE_NAME.CATEGORY_EDUCATION_CENTER,
      params: {
        category: SPECIAL_CATEGORY.FOREX
      }
    }
  },
  computed: {
    articles() {
      return [
        ...this.educationCenterArticles,
        ...this.thoughtLeadershipArticles
      ]
    }
  }
}
</script>

<style scoped lang="scss">
@import '~assets/scss/local/mixins/grid';

$maxGridChildCount: 5;

.homepage-education-center__wrapper {
  $grid-setup: (
    $columnSetup: (
      $above-desktop-xl: repeat(3, minmax(0, 100%)),
      $desktop-xl: repeat(2, minmax(0, 100%)),
      $mobile: repeat(1, minmax(0, 100%))
    ),
    $rowSetup: (
      $above-desktop-xl: repeat(2, minmax(0, auto)),
      $desktop-xl: repeat(3, minmax(0, auto)),
      $mobile: repeat(6, minmax(0, auto))
    ),
    $columnGapSetup: (
      $above-desktop-xl: 24px,
      $desktop-xl: 47px,
      $desktop-lg: 20px,
      $desktop-md: 63px,
      $tablet: 25px
    ),
    $rowGapSetup: (
      $above-desktop-xl: 25px,
      $tablet: 20px,
      $mobile: 15px
    ),
    $combinedCellsSetup: (
      2: (
        $above-desktop-xl: #{1} / #{2} / #{3} / #{3},
        $desktop-md: auto / auto / auto / auto,
        $tablet: #{1} / #{2} / #{3} / #{3},
        $mobile: auto / auto / auto / auto
      ),
      4: (
        $desktop-md: #{2} / #{2} / #{4} / #{3},
        $tablet: auto / auto / auto / auto
      )
    )
  );

  .homepage-education-center__title {
    @include tablet {
      /deep/ .homepage-section-title__delimiter {
        display: none;
      }
    }
  }

  .homepage-education-center__article-list {
    @include gridBuilder($grid-setup);

    @include desktop-md {
      margin-right: 50px;
    }

    @include tablet {
      margin-right: 0;
    }
  }

  .homepage-education-center__banner-wrapper {
    @include setStyleByBreakpoint(
      'margin-top',
      map-get($grid-setup, $rowGapSetup)
    );
  }
}
</style>
