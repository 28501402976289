<template>
  <div class="homepage-most-viewed__wrapper">
    <a-section-title title="Most Viewed" />

    <ul class="homepage-most-viewed__list">
      <li
        v-for="article in articles"
        :key="article.slug"
        class="homepage-most-viewed__list-item"
      >
        <article-slot :article="article" />
      </li>
    </ul>
  </div>
</template>
<script>
import { PROP_TYPES, propValidator } from '@/utils/validators'
import { serverCacheKey } from '@/utils/mixins/serverCacheKey'
import ASectionTitle from '@/components/_homepage/Shared/ASectionTitle'
import ArticleSlot from 'shared/ArticleSlots/Fm/Shared/AArticleSlotMostViewed'

export default {
  name: 'HomepageMostViewed',
  mixins: [serverCacheKey],
  components: { ASectionTitle, ArticleSlot },
  props: {
    articles: propValidator([PROP_TYPES.ARRAY])
  }
}
</script>

<style scoped lang="scss">
@import '~assets/scss/local/mixins/grid';

.homepage-most-viewed__wrapper {
  .homepage-most-viewed__list {
    $grid-setup: (
      $columnSetup: (
        $above-desktop-xl: repeat(2, minmax(0, 100%)),
        $mobile: repeat(1, minmax(0, 100%))
      ),
      $columnGapSetup: (
        $above-desktop-xl: 30px,
        $desktop-xl: 47px,
        $desktop-lg: 20px,
        $desktop-md: 15px,
        $tablet: 30px
      ),
      $rowGapSetup: (
        $mobile: 15px
      )
    );

    @include gridBuilder($grid-setup);
  }
}
</style>
