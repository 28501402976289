<template>
  <div v-if="article" class="education-center-article-slot__wrapper">
    <div class="education-center-article-slot__media-wrapper">
      <a-media
        :image="article.featuredImage"
        :alt="article.title"
        :image-aspect-ratio="$options.consts.aspectRatios.article"
        :video="article.featuredVideo"
        :video-aspect-ratio="$options.consts.aspectRatios.article"
        :url="article.canonicalUrl"
        :rounded="true"
        class="education-center-article-slot__featured-media"
        @play="onPlayFeaturedVideo"
        @pause="onPauseFeaturedVideo"
      />

      <fade-transition v-if="checkIfSponsored(article)" :duration="150">
        <div
          v-show="isPartnersContentStripVisible"
          class="education-center-article-slot__sponsored"
        >
          Partners Content
        </div>
      </fade-transition>
    </div>

    <a-dots
      :text="article.title"
      :row-count="topicLineCount"
      :row-height="topicLineHeight"
      :link-props="{ to: article.canonicalUrl }"
      tag="h4"
      :fixed-height="fixedHeight"
      class="education-center-article-slot__title"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import { PROP_TYPES, propValidator } from '@/utils/validators'
import AMedia from 'shared/AMedia'
import ADots from 'shared/ADots'
import { aspectRatios } from 'enums/aspectRatios'

export default {
  name: 'AArticleSlotEducationCenter',
  components: { ADots, AMedia },
  props: {
    article: propValidator([PROP_TYPES.OBJECT]),
    topicLineCount: propValidator([PROP_TYPES.NUMBER]),
    topicLineHeight: propValidator([PROP_TYPES.NUMBER]),
    fixedHeight: propValidator([PROP_TYPES.BOOLEAN])
  },
  consts: { aspectRatios },
  data() {
    return {
      isPartnersContentStripVisible: true
    }
  },
  computed: {
    ...mapGetters({
      checkIfThoughtLeadershipSlug: 'categories/checkIfThoughtLeadershipSlug'
    })
  },
  methods: {
    checkIfSponsored(article) {
      return this.checkIfThoughtLeadershipSlug(article.categorySlug)
    },
    onPlayFeaturedVideo() {
      this.isPartnersContentStripVisible = false
    },
    onPauseFeaturedVideo() {
      this.isPartnersContentStripVisible = true
    }
  }
}
</script>

<style scoped lang="scss">
.education-center-article-slot__wrapper {
  .education-center-article-slot__media-wrapper {
    position: relative;
  }

  .education-center-article-slot__sponsored {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 18px;
    border-radius: 5px;
    overflow: hidden;
    background-image: linear-gradient(
      to right,
      rgba(9, 3, 21, 0.88) 8%,
      rgba(7, 3, 18, 0.54) 50%
    );
    color: $c--white;
  }

  .education-center-article-slot__title {
    $font-size: (
      $above-desktop-xl: 18px,
      $desktop-md: 16px
    );
    $line-height: (
      $above-desktop-xl: 26px,
      $desktop-md: 22px
    );

    margin-top: 12px;
    @include setStyleByBreakpoint('font-size', $font-size);
    @include setStyleByBreakpoint('line-height', $line-height);
  }
}
</style>
