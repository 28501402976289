<template>
  <section class="homepage-sections__wrapper">
    <a-visibility show :on="[$breakpoint.desktopXl]">
      <div class="homepage-sections__banner">
        <a-mocked-events-banner
          :size="$options.consts.BANNER_SIZE.LARGE_970x250"
          class="rounded-corners"
        />
      </div>
    </a-visibility>
    <div class="homepage-sections__content">
      <div class="homepage-sections__news-strips">
        <a-visibility hide :to="$breakpoint.desktopXl">
          <div class="homepage-sections__banner">
            <a-mocked-events-banner
              :size="$options.consts.BANNER_SIZE.LARGE_970x250"
              class="rounded-corners"
            />
          </div>
        </a-visibility>
        <homepage-education-center
          v-if="isEducationSectionDataValid"
          :education-center-articles="educationCenterArticles"
          :thought-leadership-articles="thoughtLeadershipArticles"
          :last-modified="lastModifiedByRequest.FmArticles"
          class="homepage-sections__education-center"
        />
        <homepage-most-viewed
          v-if="isDataValid.MostViewedArticles"
          :articles="mostViewedArticles"
          :last-modified="lastModifiedByRequest.FmArticles"
          class="homepage-sections__most-viewed"
        />
      </div>
      <div class="homepage-sections__form-directory-assistant">
        <i class="homepage-sections__delimiter" />
        <a-newsletter-form-v2
          class="homepage-sections__newsletter-form-wrapper"
        />
        <homepage-directory
          :categories="directoryCategories"
          :companies="directoryCompanies"
          :articles="directoryNews"
          :is-data-valid="isDataValid"
          :last-modified-by-request="lastModifiedByRequest"
          class="homepage-sections__directory"
        />
      </div>
    </div>
  </section>
</template>

<script>
import { PROP_TYPES, propValidator } from '@/utils/validators'
import HomepageEducationCenter from '../HomepageEducationCenter'
import HomepageDirectory from '../HomepageDirectory'
import HomepageMostViewed from '../HomepageMostViewed'
import AMockedEventsBanner, {
  SIZE as BANNER_SIZE
} from '@/components/_homepage/Shared/AMockedEventsBanner'
import { hydrateWhenVisible } from '@/utils/helpers/vue-lazy-hydration/LazyHydrate'

export default {
  name: 'HomepageCombined',
  components: {
    HomepageEducationCenter,
    ANewsletterFormV2: hydrateWhenVisible(() =>
      import('../../../_shared/ANewsletterFormV2')
    ),
    HomepageDirectory,
    HomepageMostViewed,
    AMockedEventsBanner
  },
  props: {
    educationCenterArticles: propValidator([PROP_TYPES.ARRAY]),
    thoughtLeadershipArticles: propValidator([PROP_TYPES.ARRAY]),
    directoryCategories: propValidator([PROP_TYPES.ARRAY]),
    directoryCompanies: propValidator([PROP_TYPES.ARRAY]),
    directoryNews: propValidator([PROP_TYPES.ARRAY]),
    mostViewedArticles: propValidator([PROP_TYPES.ARRAY]),
    isDataValid: propValidator([PROP_TYPES.OBJECT]),
    lastModifiedByRequest: propValidator([PROP_TYPES.OBJECT])
  },
  consts: { BANNER_SIZE },
  computed: {
    isEducationSectionDataValid() {
      return (
        this.isDataValid.EducationArticles &&
        this.isDataValid.ThoughtLeadershipArticles
      )
    }
  }
}
</script>

<style scoped lang="scss">
@import '~assets/scss/local/mixins/homepage-latest-news';
@import '~assets/scss/global/mixins/figures';
@import '~assets/scss/local/mixins/grid';

.homepage-sections__wrapper {
  .homepage-sections__content {
    $grid-setup: (
      $columnSetup: (
        $above-desktop-xl: minmax(0, 100%) 430px,
        $desktop-xl: minmax(0, 100%) 425px,
        $desktop-lg: minmax(0, 100%) 365px,
        $desktop-md: minmax(0, 100%) 274px,
        $desktop-sm: minmax(0, 100%) 300px,
        $tablet: minmax(0, 100%)
      ),
      $columnGapSetup: (
        $above-desktop-xl: 20px
      )
    );

    @include gridBuilder($grid-setup);

    @include mobile {
      margin-top: 0; // rewrite general style "& > section {...}" in pages/index.vue
    }
  }

  .homepage-sections__delimiter {
    @include delimiter($color: $c--main, $thickness: 2px, $mb: 15px);

    @include desktop-xl {
      margin-top: 13px;
    }

    @include desktop-md {
      margin-top: 0;
    }
  }

  .homepage-sections__education-center {
    $margin-top: (
      $above-desktop-xl: 30px,
      $desktop-md: 0,
      $tablet: 30px
    );
    @include setStyleByBreakpoint('margin-top', $margin-top);
  }

  .homepage-sections__most-viewed {
    $margin-top: (
      $above-desktop-xl: 30px,
      $desktop-md: 45px,
      $desktop-sm: 30px,
      $mobile: 0
    );

    @include setStyleByBreakpoint('margin-top', $margin-top);
  }

  .homepage-sections__form-directory-assistant {
    $section-title-line-height: 28px;
    $delimiter-height: 2px;

    $margin-top: (
      $desktop-xl: 30px,
      $desktop-md: (
        $section-title-line-height - $delimiter-height
      ) / 2,
      $tablet: 0
    );
    @include setStyleByBreakpoint('margin-top', $margin-top);

    @include tablet {
      max-width: 100vw;
      order: -1;
      background: #f5f5f5;
      @include fullWidthWithoutPadding;
    }
  }

  .homepage-sections__directory::before,
  .homepage-sections__form-directory-assistant::after {
    @include tablet {
      @include delimiter($c--main, 2px, 10px, 15px);
    }
  }

  .homepage-sections__directory::before {
    width: auto;
    margin-left: -20px; // this is not a general page indent but only Directory section itself
    margin-right: -20px;
  }

  .homepage-sections__form-directory-assistant::after {
    @include tablet {
      margin-top: 0;
      margin-bottom: 0;
    }

    @include mobile {
      display: none;
    }
  }
}
</style>
