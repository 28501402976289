<template>
  <div v-if="article" class="most-viewed-article-slot__wrapper">
    <a-media
      :image="article.featuredImage"
      :alt="article.title"
      :image-aspect-ratio="$options.consts.aspectRatios.article"
      :video="article.featuredVideo"
      :video-aspect-ratio="$options.consts.aspectRatios.article"
      :url="article.canonicalUrl"
      :rounded="true"
      class="most-viewed-article-slot__featured-media"
    />

    <div class="most-viewed-article-slot__title-wrapper">
      <a-dots-responsive
        :text="article.title"
        tag="h4"
        :link-props="{ to: article.canonicalUrl }"
        :settings="$options.consts.topicSettings"
        class="most-viewed-article-slot__title"
      />
    </div>
  </div>
</template>

<script>
import { PROP_TYPES, propValidator } from '@/utils/validators'
import AMedia from 'shared/AMedia'
import ADotsResponsive, { configADots } from 'shared/ADotsResponsive'
import { aspectRatios } from 'enums/aspectRatios'
import scss from '@/utils/scss'

const { breakpoints } = scss

export default {
  name: 'AArticleSlotMostViewed',
  components: { ADotsResponsive, AMedia },
  props: {
    article: propValidator([PROP_TYPES.OBJECT])
  },
  consts: {
    aspectRatios,
    topicSettings: {
      [breakpoints.aboveDesktopXl]: configADots(2, 26, true),
      [breakpoints.desktopMd]: configADots(3, 18, true),
      [breakpoints.tablet]: configADots(2, 26, true),
      [breakpoints.mobile]: configADots(3, 20, false)
    }
  }
}
</script>

<style scoped lang="scss">
.most-viewed-article-slot__wrapper {
  position: relative;

  .most-viewed-article-slot__title-wrapper {
    position: absolute;
    bottom: 15px;
    left: 0;
    width: 100%;
    padding: 17px 13px;
    background-image: linear-gradient(
      to right,
      rgba(9, 3, 21, 0.88) 8%,
      rgba(7, 3, 18, 0.54) 50%
    );
  }

  .most-viewed-article-slot__title {
    $font-size: (
      $above-desktop-xl: 18px,
      $desktop-md: 16px,
      $tablet: 18px,
      $mobile: 16px
    );

    @include setStyleByBreakpoint('font-size', $font-size);

    /deep/ .dots__visible > a {
      color: $c--white;
    }

    /deep/ .dots__visible > a:hover {
      color: $c--main;
    }
  }
}
</style>
